.background {
    background: linear-gradient(96.87deg, #2C73D6 0%, #2365BD 100%) !important;
    color: white;
    padding: 30px 0px;
}

.footer-container {
    max-width: 80%;
    margin: auto;

    .branding {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        align-items: center;

        img {
            margin-left: -5px;
        }
    }

    .footer-content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-bottom: 1px solid white;

        .list-item {
            list-style: none;
            padding: 0;

            li {
                font-size: 14px;
                padding: 5px 0px;
            }
        }

        h3 {
            font-size: 16px;
            color: white;
        }
    }

    .arrow {
        display: none;
    }
}

@media only screen and (max-width: 575px) {
    .footer-container {
        max-width: 90%;

        .arrow {
            border: solid white;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            position: absolute;
            right: 15px;
            top: 10px;
        }

        .down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        .right {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }



        .footer-content {
            display: block;

            h3 {
                position: relative;
                padding: 5px 0px;
                // border-top: 1px solid;
                // border-bottom: 1px solid;
            }

            .list-item {
                li {
                    padding-left: 10px;
                }
            }
        }

        .branding {
            display: block;

            .coditas {
                padding-bottom: 20px;
            }
        }
    }
}