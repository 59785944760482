$borderColor: #cdd7e5;

.schedule {
  min-height: 53px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  margin: 0px -40px;
  .datetime {
    display: flex;
  }
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.event-details-form {
  // max-height: 20vh;
  // overflow-y: scroll;
  li {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
}

.left-panel {
  width: 30%;
  // max-width: 384px;
  border-right: 1px solid $borderColor;
  padding: 40px;

  h3 {
    font-weight: 600;
    margin: 0;
  }
}

.presenter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0px;

  .p1 {
    // width: 45%;
    display: flex;
    align-items: center;
    margin: 10px 0px;
    min-height: 58px;
    min-width: 50%;
    max-width: 50%;

    img {
      border-radius: 100%;
      height: 65px;
      width: 65px;
      border: 4px solid #f1f1f1;
    }
  }

  p {
    margin: 0;
  }

  .presenter-info {
    margin-left: 10px;

    .by {
      font-size: 14px;
      font-weight: 600;
    }

    .desg {
      font-size: 12px;
    }
  }
}

.visibleMobile {
  display: none;
}

.event-about {
  padding-top: 30px;

  .about {
    text-transform: uppercase;
    font-weight: bold;
    color: #07172d;
    opacity: 0.54;
  }
}

@media only screen and (max-width: 575px) {
  .form-container {
    display: block;
  }

  .event-about {
    padding-top: 0px;
    // padding-bottom: 20px;
  }

  .expanded {
    max-height: 900px;
    opacity: 1;
    padding-top: 20px;
    transition: max-height 0.3s ease-in;
  }

  .collapse {
    transition: max-height 0.3s ease-out;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
  }

  .visibleMobile {
    display: block;
    padding: 10px;
    // margin: 0px -30px;
    font-weight: 600;
    color: #2c74d6;
    border-bottom: 1px solid $borderColor;
    margin-bottom: 20px;
  }

  .form-inputs {
    padding: 0;
    width: 100%;
  }

  .left-panel {
    width: 100%;
    max-width: 100%;
    border: none;
    padding: 0;
  }
}
