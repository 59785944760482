.eventnav {
  background: linear-gradient(96.87deg, #2C73D6 0%, #2365BD 100%) !important;
  position: fixed;
  width: 100%;

  .navbar-container {
    background-color: transparent;
    position: relative;


    .nav-item {
      color: white;
    }
  }

  .desc {
    color: white;
    margin-left: 10px;
    max-width: 900px;
  }

  .newcontainer {
    max-width: 1600px;
    display: flex;
    padding: 40px;

    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 15px;

    h1 {
      margin: 0;
      font-weight: bold;
      color: white;
      font-size: 3rem;
      transition: all 0.3s ease-in;
      margin-left: 10px;
    }
  }

  .button-container {
    margin: 0;
  }

  .arrow-event {
    display: flex;
  }
}


.visibleOnMobile {
  display: none;
}

.flex {

  // background-color: #0b0b3c;
  // width: 100%;
  // position: fixed;
  // padding-bottom: 40px;
  .navbar-container {
    position: fixed;
  }

  .newcontainer {
    padding-top: 75px;

    .nav-top {
      display: flex;

      h1 {
        transition: all 0.3s ease-out;
        font-size: 2rem;
      }
    }
  }

}

.block {
  // .newcontainer {
  //   padding-top: 100px;
  // }
}