@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body,
#eventroot {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap');

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hover {
  cursor: pointer;
}


body {
  font-family: Montserrat, sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.visbileOnMobile {
  display: none;
}


@media only screen and (max-width: 575px) {
  .mainevent {
    padding: 40px 16px;
  }

  .event-details {
    position: unset;
  }

  .banner {
    width: 100%;
  }


  .title {
    display: none;
  }

  .visbileOnMobile {
    display: block;
  }

  html {
    min-width: 100%;
  }

  .fixed-top {
    position: relative;
  }

  .ant-form-item .ant-form-item-label {
    padding: 20px 0px !important;
  }

  .ant-form-item-control-input {
    padding: 0;
  }

  .navbar-row {
    //display: none !important;
  }

  .form-content {
    top: 40px;
  }

  .navbar-row {
    //display: none;
  }

  .form-container {
    margin: 0;
    width: 100%;
    padding: 0;
    border: none;
  }

  .btm {
    border: none;
    padding: 0;
    margin: 0;
  }

  form {
    grid-template-columns: repeat(1, 1fr);
  }
}