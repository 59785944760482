.load-wrapp {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #0000007a;
    z-index: 100;
}

.square {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    background-color: #4b9cdb;
}

.square-holder {
    display: flex;

}

.load-7 {

    .square {
        margin: 10px;
        animation: loadingG 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
    }
}

@keyframes loadingG {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }

    50% {
        transform: translate(70px, 0) rotate(360deg);
    }

    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}