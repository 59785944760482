@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap);

#eventroot {}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-form-item-label>label {
  font-family: "Roboto", sans-serif;
  font-weight: 400 !important;
}

.ant-form-item {
  padding: 15px 15px 15px 0 !important;
  display: inline-block !important;
  max-width: 50%;
  width: 50%;
  flex-direction: column !important;
  vertical-align: unset !important;
  margin-bottom: 0 !important;
}

.ant-form-item-control-input {
  padding-left: 10px;
}

input,
textarea {
  padding: 15px !important;
  border-radius: 10px !important;
  resize: none !important;
}

.ant-form-item-label {
  white-space: normal !important;
  text-align: left !important;
  max-width: 100% !important;
  padding: 10px 0;
}

.ant-form-item-label>label {
  font-weight: 400;
  display: flex;
  flex-direction: row-reverse;
  justify-content: unset;
  float: left;
  height: auto !important;
}

.ant-radio-group {
  display: flex !important;
  flex-direction: column !important;
}

form {
  grid-template-rows: auto;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(2, 1fr);
}

.feedback-form {
  max-width: 900px;

  .feedback {
    max-width: 100%;
    width: 100%;

    .ant-form-item {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.form-inputs {
  padding: 70px 40px 20px;
  width: 66%;
}

.form-out {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.my-form {
  margin-left: auto;
  margin-right: auto;
  background-color: #0b0b3c;
  padding: 30px;
  font-family: "Montserrat", sans-serif;
  padding-top: 70px;
  z-index: -1;
}

.ant-input,
.ant-input-affix-wrapper
.ant-select-selector {
  border: 1px solid #000 !important;
  font-size: 1rem !important;
  height: auto !important;
  // margin: 20px 0px !important;
  outline: 0 !important;
  padding: 14px !important;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 8px !important;
}

.webinar-title {
  font-size: 20px;
  color: #fff !important;
}

.my-header {
  text-align: left;
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
}

.fixed-top {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.form-content-container {
  background: #f8f8f8;
}

.form-content {
  max-width: 1600px;
  display: grid;
  grid-template-columns: minmax(100%, 1200px);
  margin: 0 auto;
  padding: 40px;
  padding-top: 230px;
}

.form-container {
  border-radius: 8px;
  border: 1px solid #cdd7e5;
  margin: 0 auto;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  background-color: white;

  width: 100%;
  padding: 0;
}

.ant-form-item-has-error {

  #isAvailableForEvent,
  #experience {
    border-color: #ff4d4f !important;
  }
}

#hearedFrom {
  justify-content: flex-start !important;
  padding: 10px;
  display: block !important;
  flex-wrap: wrap;
}

#isAvailableForEvent,
#hearedFrom,
#experience {
  display: flex;
  flex-direction: row !important;
  min-height: 55px;
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  justify-content: space-evenly;
  align-items: center;
  border-color: #dadada;

  label {
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .ant-radio-inner {
    border-color: #2c74d6;
    border-width: 2px;

    &::after {
      top: 2px;
      left: 2px;
    }
  }
}

#experience {
  padding-left: 10px;
}

.ant-form-item-has-error {
  input {
    border-color: #ff4d4f !important;
    border-width: 2px;
  }
}

.form-top {
  display: flex;
  justify-content: space-between;
}

.mandatory {
  color: red;
}

.ant-col {
  width: 100%;
  max-width: 100% !important;

  label {
    &::before {
      display: none !important;
    }
  }
}

h3 {
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
}

.content {
  color: #fff;
  padding-bottom: 20px;
}

.content,
.logo-container {
  text-align: left;
}

.logo {
  width: 100px;
}

.bottom {
  text-align: right;
}

.btm {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  height: auto;
  margin: 30px 0 16px;
  padding-top: 35px;
}

.ant-radio-wrapper {
  padding: 5px 0 !important;
}

.ant-btn-primary {
  height: 40px !important;
  padding: 0 20px !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
}

.ant-form-item-children-icon {
  z-index: 0 !important;
}

.form-button {
  text-transform: uppercase;
  height: 52px;
  justify-self: end;
  font-family: Roboto;
  background-color: #2c74d6;
  padding: 0 20px;
  color: #fff;
  border: 2px solid #2c74d6;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1rem;
  outline: none;
}

.form-button:hover {
  cursor: pointer;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  padding-left: 6px;
}

.ant-form-item-explain.ant-form-item-explain-error {
  padding-left: 10px !important;
}

.ant-modal-header {
  display: none;
}

.ant-modal-content {
  border-radius: 8px !important;
}

.ant-modal-body {
  height: 200px !important;
  text-align: center !important;
}

.anticon-anticon-check-success {
  background: #2c74d6 !important;
}

.anticon-anticon-check-failed,
.anticon-anticon-check-success {
  color: #fff !important;
  border-radius: 30px !important;
  font-size: 26px !important;
  padding: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}

.anticon-anticon-check-failed {
  background: red !important;
}

.ant-modal-footer {
  text-align: center !important;
}

.ant-form-item-extra {
  padding-left: 12px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 20s linear infinite;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@media only screen and (max-width: 575px) {}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-container {
  position: fixed;
  cursor: not-allowed;
  z-index: 100000;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3215686274509804);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 14px;
  height: 100px;
  margin: 8px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 8px solid transparent;
  border-top-color: #3a89ab;
}

.lds-ring div:first-child {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.navbar-row {
  list-style: none;
  display: flex;
  margin: 0;
}

.nav-item {
  padding: 10px;
  font-weight: 500;
}

.nav-item:hover {
  cursor: pointer;
  margin-bottom: -2px;
  border-bottom: 2px solid #fff;
}

/*# sourceMappingURL=main.2c564d7d.chunk.css.map */