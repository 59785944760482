.navbar-container {
  display: flex;
  margin: auto;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  z-index: 100;
  width: 100%;
  position: fixed;
}

.blue {
  background-color: #e0ecee;
  transition: all 0.2s ease-in;
}

.white {
  background-color: white;
  transition: all 0.2s ease-out;
}

.nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1560px;
  margin: auto;
  padding: 0px 40px;
}

.image-logo {
  color: blue;
  margin-left: -1rem;

  &:hover {
    cursor: pointer;
  }
}

.navbar-row {
  list-style: none;
  display: flex;
  margin: 0;
}

.nav-item {
  padding: 10px;
  font-weight: 500;
  border: none;
  transition: border 0.2s ease-in;

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid rgb(255, 255, 255);
    margin-bottom: -2px;
    transition: border 0.2s ease-in;
  }
}

.nav-item.active {
  cursor: pointer;
  border-bottom: 2px solid rgb(255, 255, 255);
  margin-bottom: -2px;
  transition: border 0.2s ease-in;
}