@media only screen and (max-width: 575px) {
    html {
        min-width: 100%;
    }


    .form-top {
        display: block;
    }

    .my-header {
        font-size: 2rem;
    }

    .form-button {
        width: 100%;
    }

    .schedule {
        margin: 0;
        font-size: 14px;
        padding-left: 0;
        text-align: center;
        border: none;
    }



    .form-content {
        padding: 0;
        overflow: hidden;
    }

    .info-image {
        flex-direction: column-reverse;
        padding: 0;
    }

    .form-inputs {
        // padding: 20px;
    }

    .presenter {
        display: none;
    }

    .show {
        display: flex;
    }

    .fixed-top {
        position: relative;
    }

    .ant-form-item-control-input {
        padding: 0;
    }


    .image-logo {
        color: blue;
    }

    .navbar-hidden {
        // display: none ;
    }

    .navbar-mobile {
        background: linear-gradient(96.87deg, #2C73D6 0%, #2365BD 100%) !important;
        width: 100%;
        flex-direction: column;
        height: 100%;
        top: 60px;
        position: fixed;
        z-index: 1000;
        left: -2px;
        list-style: none;
        padding-left: 10px;
        display: flex;
        transition: left 300ms ease-out;
    }

    .navbar-row {
        //display: none !important;
        display: flex;
        flex-direction: column;
    }


    .form-container {
        margin: 0;
        padding: 20px;
        border: none;
    }

    .navbar-container {
        min-height: 60px;
    }

    /* Icon 1 */
    #nav-icon1,
    #nav-icon2,
    #nav-icon3,
    #nav-icon4 {
        width: 60px;
        position: absolute;
        top: 20px;
        right: 0;
        margin-left: 18rem;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    #nav-icon1 span,
    #nav-icon3 span,
    #nav-icon4 span {
        display: block;
        position: absolute;
        height: 2px;
        width: 30px;
        background: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    #nav-icon1 span:nth-child(1) {
        top: 10px;
    }

    #nav-icon1 span:nth-child(2) {
        top: 20px;
    }

    #nav-icon1 span:nth-child(3) {}

    #nav-icon1.open span:nth-child(1) {
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    #nav-icon1.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
    }

    #nav-icon1.open span:nth-child(3) {
        top: 10px;

        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }


    .btm {
        border: none;
        padding: 0;
        margin: 0;
    }

    .form-out {
        display: block;
    }

    .btm {
        text-align: center;
    }

    html {
        min-width: 100%
    }

    .fixed-top {
        position: relative
    }

    .ant-form-item {
        padding: 15px 0px !important;
        width: 100%;
        max-width: 100%;
    }

    .ant-form-item-control-input {
        padding: 0
    }


    .navbar-row {
        // display: flex;
        display: none;
    }

    .ant-input {
        margin: 5px 0px !important;
    }

    .navbar-row {
        // display: none
    }

    .form-container {
        width: 100%
    }

    form {
        grid-template-columns: repeat(1, 1fr)
    }

    .bottom {
        padding-top: 16px;
        border-top: 1px solid #CDD7E5;
        margin-top: 10px;

    }

    .column {
        ul {
            margin: 0;
        }
    }

    .column:nth-child(2),
    .column:nth-child(3),
    .column:nth-child(4) {
        padding-top: 10px;
        border-top: 1px solid;
    }


    // Navbar


    .flex,
    .block {
        .navbar-container {
            position: fixed;
            z-index: 1000;
            background: linear-gradient(96.87deg, #2C73D6 0%, #2365BD 100%) !important;
        }
    }


    .eventnav {
        .newcontainer {
            max-width: 100%;
            padding-top: 50px;

            .arrow-event {
                width: 100%;
                padding: 0 15px;
                display: flex;

                h1 {

                    font-size: 2.3rem;
                }
            }

            .nav-top {
                h1 {
                    font-size: 2.3rem;
                }
            }
        }
    }

    .newcontainer {
        flex-direction: column;

        .butt-div {
            width: 100%;
            margin-top: 20px;
            padding: 0 15px;

            .blue-button {

                width: 100%;
            }
        }
    }

    .nav-inner {
        padding: 0 5%;
    }

    // Home Page
    .home-content {
        top: 50px;
        z-index: 0;
    }

    .banner-container {
        padding: 30px 0px;
        // z-index: -1;
    }

    .main__container {
        padding: 0 16px;
    }

    .image-container {
        display: none;
    }

    .details-events {
        width: auto;
    }

    .button-component .heading__event {
        // display: none;
    }

    .speaker-heading {
        font-size: 2rem;
    }

    // Details Page

    .event-details {
        padding-top: 205px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .calender-clock {
        display: flex;
        flex-direction: revert;
        margin-top: 10px;
        // justify-content: space-evenly;
        padding-bottom: 10px;
        margin-left: -5px;

        .datetime {
            width: 50%;
        }
    }

    .calender,
    .clock {
        justify-content: flex-start;
        width: auto;
    }

    // Ucoming Cards

    .upcomingEventCards {
        display: block;

        .banner {
            width: 100%;

            .image_banner {
                width: 100%;

            }

            .play_button_img {}
        }

        .card {
            position: relative;
            width: 100%;
            margin: 15px 0px;
        }
    }

    // Past Events

    .pastEventCards {
        flex-direction: column;
        max-height: 200px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;

        .pastevent {
            width: 282px;
            max-width: 100%;

            .present {
                font-size: 12px;
                margin-top: 8px;

            }
        }
    }

    .visibleOnMobile {
        display: block;
    }


    .datetime {
        display: flex;
        font-size: 14px;
        flex-direction: inherit;
        align-items: center;

        span {
            font-size: 12px;
        }

        svg {
            margin: 0px 5px;
        }
    }

    .button-component {
        display: block;
        margin-top: 20px;

        .button-container {
            margin: 0;
        }

        button {
            width: 100%;
            margin: 5px 0px;
        }
    }

    .event-speaker {
        font-size: 20px;
        width: 100%;
    }

    .heading__event {
        margin-top: 30px;
        margin-bottom: 0;
        // display: none;
        font-size: 32px;
    }

    .main-head {
        display: none;
    }

    .past-container {
        padding: 20px 16px;

    }

}

@media only screen and (min-width:620px) and (max-width: 1200px) {

    .alignCards {
        padding: 20px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

    }
}