@mixin buttonS {
  min-height: 56px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px;
  padding: 0 15px;
}

.blue-button {
  background-color: #2c74d6;
  border: none;
  color: white;
  @include buttonS()
}

.button-outlined {
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.16);
  color: #2C74D6;
  @include buttonS();
}

.button-container {
  margin-right: 30px;
}

button:hover {
  cursor: pointer;
}